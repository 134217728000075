import { useCallback, useEffect, useState } from 'react';

import useStableRef from './use-stable-ref';

// JSON.stringify can return undefined when passed undefined. The most thorough
// solution would involve constructing a complete type definition comprising all
// possible JSON-serializable values, but for now, I'm going to handle it as a
// run-time exception.
function safeJSONStringify(value: unknown): string {
	const result = JSON.stringify(value);
	if (result == null) {
		throw new TypeError(
			'Value passed to useLocalStorage() was not JSON-serializable.',
		);
	}
	return result;
}

function deserializeCurrentValue<T>(
	initialValue: T,
	key: string,
	deserialize: (serialized: string) => T,
	storage: Storage,
): T {
	const currentSerializedValue = storage.getItem(key);
	return currentSerializedValue == null
		? initialValue
		: deserialize(currentSerializedValue);
}

function useStorage<T>(
	key: string,
	initialValue: T,
	serialize: (value: T) => string,
	deserialize: (serialized: string) => T,
	storage: Storage,
): [T, (value: T | ((currentValue: T) => T)) => void, () => void] {
	const hookName =
		storage === localStorage ? 'useLocalStorage' : 'useSessionStorage';

	useStableRef(initialValue, `${hookName} initialValue`);
	const [currentValue, setCurrentValue] = useState(() =>
		deserializeCurrentValue(initialValue, key, deserialize, storage),
	);

	useEffect(() => {
		setCurrentValue(
			deserializeCurrentValue(initialValue, key, deserialize, storage),
		);
	}, [deserialize, initialValue, key, storage]);

	const setter = useCallback(
		(newValue: T | ((currentValue: T) => T)): void => {
			if (newValue instanceof Function) {
				const previousValue = deserializeCurrentValue(
					initialValue,
					key,
					deserialize,
					storage,
				);

				const nextValue = newValue(previousValue);
				storage.setItem(key, serialize(nextValue));
				setCurrentValue(nextValue);
			} else {
				storage.setItem(key, serialize(newValue));
				setCurrentValue(newValue);
			}
		},
		[deserialize, initialValue, key, setCurrentValue, serialize, storage],
	);

	const clear = useCallback(() => {
		storage.removeItem(key);
		setCurrentValue(initialValue);
	}, [key, initialValue, storage]);

	return [currentValue, setter, clear];
}

/**
 * Persist component state inside the browser's sessionStorage[1].
 * Consider session storage for component state that should be preserved across
 * a single browser tab, but not across new tabs.
 *
 * Carefully consider the life cycle of sessionStorage before using it for your component state
 *
 * [1]: https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
 */
export function useSessionStorage<T>(
	key: string,
	initialValue: T,
	serialize: (value: T) => string = safeJSONStringify,
	deserialize: (serialized: string) => T = JSON.parse,
): [T, (value: T | ((currentValue: T) => T)) => void, () => void] {
	return useStorage(
		key,
		initialValue,
		serialize,
		deserialize,
		sessionStorage,
	);
}

/**
 * Persist component state inside the browser's localStorage[1].
 * Consider local storage for component state that should be preserved across
 * multiple reloads and new browser tabs.
 *
 * [1]: https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
 */
export function useLocalStorage<T>(
	key: string,
	initialValue: T,
	serialize: (value: T) => string = safeJSONStringify,
	deserialize: (serialized: string) => T = JSON.parse,
): [T, (value: T | ((currentValue: T) => T)) => void, () => void] {
	return useStorage(key, initialValue, serialize, deserialize, localStorage);
}
