import mixpanel from 'mixpanel-browser';

import { subscribe } from '../authentication';
import { mixpanelProxyHost, mixpanelToken, releaseVersion } from '../config';

// MIXPANEL

mixpanel.init(mixpanelToken, {
	api_host: mixpanelProxyHost,
});

subscribe((user) => {
	const username = user ? user.username : '(anonymous)';

	mixpanel.identify(username);
	if (user) {
		// Although it's semi-redundant to send both the tenant's id and the
		// tenant's name, we want to make sure we don't lose all of a tenant's
		// past data should they change their name (Sitti --> Tandem). While we
		// are unable to map the name to the associated id in Mixpanel, we do
		// have the ability to "merge" the two properties & pick which is used
		// as the display name.
		// https://help.mixpanel.com/hc/en-us/articles/360001307806-Lexicon-Overview#h_6786431932251547692479690
		const tenantId = user.tenant ? user.tenant.id : null;
		const tenantName = user.tenant ? user.tenant.name : '';

		// Difference between .register() & .set() according to Mixpanel:
		//
		// You can use the people properties to filter off of in events, so you
		// should use mixpanel.register() method for any values that might
		// change over time, and the mixpanel.people.set() method for all values
		// that you want to tie to the user. If you update the people
		// properties, you'll lose the previous value and there will not be a
		// history of that value in your project. So depending on your use case,
		// you may want to use the register and people.set() methods together.
		// https://community.mixpanel.com/data-management-10/difference-between-register-and-people-set-43?postid=44#post44
		if (process.env.NODE_ENV !== 'development') {
			mixpanel.register({
				'git commit': releaseVersion,
			});
		}
		mixpanel.register({
			'Tenant ID': tenantId,
			'Tenant Name': tenantName,
			'User Groups': user.groups,
		});
		mixpanel.people.set({
			username,
			name: `${user.first_name} ${user.last_name}`,
			email: user.email,
			'Tenant ID': tenantId,
			'Tenant Name': tenantName,
			'User Groups': user.groups,
		});

		// BEFORE YOU ADD A NEW GROUP_KEY HERE, YOU MUST ADD IT TO MIXPANEL. YOU
		// WILL NOT BE COLLECTING DATA FOR THE GROUP IF YOU DO NOT ADD IT TO
		// MIXPANEL FIRST:
		// https://mixpanel.com/report/578101/settings/#project/578101
		//
		// Adding users to groups causes the group_key and group_id to send as a
		// property key and value for all events triggered by that user on the
		// device. You can add multiple values for a single user to the
		// group_key list property. Because we do not currently have the ability
		// to update Mixpanel when a user is removed from a tenant/user group,
		// we choose to reset all.
		// https://developer.mixpanel.com/docs/javascript#group-analytics
		mixpanel.set_group('userGroups', user.groups);
		mixpanel.set_group('tenantName', tenantName);
	}
});

export type EventName =
	| 'Add Attachment'
	| 'Add to Candidate Pool'
	| 'Add Note'
	| 'Add Public Comp'
	| 'Add Search Sort'
	| 'Add to Pipeline'
	| 'Add to Watch List'
	| 'Add Company Market Map Recommendation'
	| 'Adding Market Map Company'
	| 'Adding Watch List Column'
	| 'Apply Market Map Filter'
	| 'Apply Note Format'
	| 'Apply Note Template'
	| 'Apply Search Filter'
	| 'Attempt Login'
	| 'Attempt Two Factor'
	| 'Cancel Market Map Edits'
	| 'Cancel Profile Merge'
	| 'Clear Search Filter'
	| 'Clear Search Sort'
	| 'Click Address Link'
	| 'Click Customize Table Button'
	| 'Click Header'
	| 'Click Info Alert'
	| 'Click Search Suggestion'
	| 'Click Sidebar Tab'
	| 'Click Social Link'
	| 'Copy Email Address'
	| 'Copy Phone Number'
	| 'Confirm Profile Merge'
	| 'Create Funnel Action'
	| 'Create Market Map Region'
	| 'Create Market Map'
	| 'Create One Pager'
	| 'Create Profile'
	| 'Creating Watch List'
	| 'Delete Attachment'
	| 'Delete Market Map Region'
	| 'Delete New Market Map Region'
	| 'Delete Note'
	| 'Delete One Pager'
	| 'Delete Public Comp'
	| 'Deleting Watch List'
	| 'Deleting Watch List Column'
	| 'Dismiss Info Alert'
	| 'Dismiss Outreach'
	| 'Dismiss Market Map Company Recommendation'
	| 'Dismiss Similar Companies Search Result'
	| 'Download Attachment'
	| 'Download Table'
	| 'Dragged Watch List Column'
	| 'Edit Note'
	| 'Edit One Pager'
	| 'Editing Market Map'
	| 'Edited Watch List Column Name'
	| 'Editing Watch List'
	| 'Filter Market Map Recommendations By Founded Year'
	| 'Filter Watch Lists Ownership'
	| 'Finish Registration'
	| 'Hover Over Section'
	| 'Mark Robot Profile'
	| 'Moving Market Map Companies'
	| 'Open Mailto'
	| 'Open Outreach Communication Thread'
	| 'Open Profile'
	| 'Open Profile Navigator Item'
	| 'Print One Pager'
	| 'Prompted For Security Code'
	| 'Refresh Similar Companies Search'
	| 'Remove Search Sort'
	| 'Remove from Candidate Pool'
	| 'Remove from Watch List'
	| 'Removing Market Map Company'
	| 'Rename Market Map Region'
	| 'Reorder Search Sort'
	| 'Request Password Reset Email'
	| 'Resize Market Map Region'
	| 'Reverted Watch List Column Name'
	| 'Save Market Map Edits'
	| 'Save One Pager'
	| 'Save Semi Annual'
	| 'Save To WatchList'
	| 'Scroll'
	| 'Scroll Search Filters'
	| 'Scroll Table'
	| 'Snooze Outreach'
	| 'Sort Table'
	| 'Toggle Email List'
	| 'Toggle Include Subsidiaries'
	| 'Toggle Search Sort'
	| 'Toggle Travel Recommendation Strategy'
	| 'Toggle Warm Intros List'
	| 'Update Account Information'
	| 'Update Search Entity Type'
	| 'Update Search Pagination'
	| 'Update Search Query Type'
	| 'Update Search Query'
	| 'Visit New Page'
	| 'Expand Pipeline Card';

export type ViewType =
	| 'accounts'
	| 'advanced-search'
	| 'calendar-events'
	| 'customers'
	| 'companies'
	| 'cross-connect'
	| 'dataroom'
	| 'finish-registration'
	| 'global'
	| 'growth-map'
	| 'home-page'
	| 'investor-kpis'
	| 'job-order-detail'
	| 'job-orders'
	| 'login'
	| 'lp-fundraising'
	| 'marketmaps'
	| 'notes'
	| 'onepagers'
	| 'overdrive'
	| 'people'
	| 'pipelines'
	| 'portfolio'
	| 'portfolio-fundraising'
	| 'portfolio-talent'
	| 'quill'
	| 'search'
	| 'semiannuals'
	| 'stats'
	| 'talent-overdrive'
	| 'travel'
	| 'watchlists'
	| 'watchlist-detail';

/**
 * @param eventName The name of the event/action to track.
 * @param componentIdentifier The place/component on which the event/action was taken.
 * @param viewType The view where the event/action was taken. Should match the types defined throughout Herbie (companies, people, marketmaps, etc.)
 * @param [properties] Any additional information to include with event.
 *
 * @example <caption>User saves a note on a company profile</caption>
 * trackEvent('Click Save Button', 'note-section', 'companies')
 * @example <caption>User hovers over LinkedIn social icon on a person's profile</caption>
 * trackEvent('Hover Over Icon', 'social-link-section', 'people', {'Link Destination': 'LinkedIn'})
 * @example <caption>User opens Atlas to peruse all of Drive's market maps.</caption>
 * trackEvent('Open New View', 'marketmaps-list', 'marketmaps')
 * @example <caption>User connects inbox via their account page.</caption>
 * trackEvent('Connected Inbox', 'google-oauth-button', 'accounts')
 * @example <caption>User opens Brandon's "Cool Rocket Stuff" watch list.</caption>
 * trackEvent('Open New View', 'watchlist-detail', 'watchlists', {"User Is Owner": False, "List Owner": "Brandon", "List Name": "Cool Rocket Stuff"})
 */
export function trackEvent(
	eventName: EventName,
	componentIdentifier: string,
	viewType: ViewType,
	properties?: Record<string, unknown>,
): void {
	mixpanel.track(eventName, {
		Component: componentIdentifier,
		'View Type': viewType,
		...properties,
	});
}
